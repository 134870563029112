import { call, put } from "redux-saga/effects";
import { headerApi } from "../../api";
import {
  FETCH_HEADER_SUCCESSES,
  FETCH_HEADER_ERROR
} from "../../actions/actionTypes";
import {toggleSpinerStatus, toggleToastStatus} from "../../actions/actions";

export function* getHeader() {
  try {
    yield put(toggleSpinerStatus(true));
    const response = yield call(() => headerApi());
    const responseArr = yield Object.values(response);
    yield put({ type: FETCH_HEADER_SUCCESSES, payload: responseArr });
    yield put(toggleSpinerStatus(false));
  } catch (error) {
    yield put({ type: FETCH_HEADER_ERROR, payload: error.message });
    yield put(toggleSpinerStatus(false));
    yield put(toggleToastStatus({variant: 'error', toastText: `Щось пішло не так...`}));
  }
}
