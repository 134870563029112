import styled from "styled-components";

export default {
    About: styled.div`
    width: 100%;
    min-height: 570px;
    box-sizing: border-box;
    height: auto;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    font-family: Nunito sans-serif;
    margin: 15px 0;
	 @media (max-width: 1150px) {
      min-height: 470px;
    };
	 @media (max-width: 900px) {
      min-height: 390px;
    };
	 @media (max-width: 830px) {
      min-height: 300px;
    };
    @media (max-width: 600px) {
      min-height: 205px;
    };
	 @media (max-width: 500px) {
      min-height: 570px;
    };
  `,
  AboutContainer: styled.div`
     display: flex;
     height: auto;
     width: 100%;
     justify-content: space-evenly;
     align-items: center;
	  @media (max-width: 500px) {
      flex-direction: column;
     };
  `,
  AboutPhoto: styled.div`
    height: 500px;
    width: 49%;
	 @media (min-width: 1600px) {
	 	height: 800px;
    	width: 49%;
    };
    @media (max-width: 1150px) {
      height: 450px;
    };
    @media (max-width: 900px) {
      height: 370px;
    };
	 @media (max-width: 830px) {
      height: 260px;
    };
	 @media (max-width: 600px) {
      height: 205px;
    };
    @media (max-width: 500px) {
      height: 350px;
      width: 100%;
    };
  `,
  AboutInfo: styled.div`
    height: 500px;
    width: 49%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: Roboto-Condensed,sans-serif;
    line-height: 25px;
    font-size: 21px;
    letter-spacing: 1px;
    overflow: auto;
	 @media (min-width: 1600px) {
	 	height: 800px;
    	width: 49%;
    };
    @media (max-width: 1150px) {
      height: 450px;
    };
    @media (max-width: 900px) {
      height: 370px;
      font-size: 18px;
    };
	 @media (max-width: 830px) {
      height: 270px;
    };
	 @media (max-width: 600px) {
      height: 205px;
    };
    @media (max-width: 500px) {
      height: 200px;
      width: 100%;
    };
  `,
  ContactsContainer: styled.div`
     border: 2px solid #B8860B;
     box-sizing: border-box;
     display: flex;
     flex-direction: column;
     align-items: center;
     height: auto;
     padding-top: 5px;
     width: 100%;
  `,
  ContactsLogo: styled.div`
     height: 150px;
     width: 100%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
  `,
  ContactsInfo: styled.div`
     height: auto;
     flex-wrap: wrap;
     width: 100%;
     display: flex;
     justify-content: space-evenly;
     align-items: center;
  `,
  ContactsDiv: styled.div`
    display: flex;
    flex-direction: column;
	  text-align: center;
  `,
  ContactsHeader: styled.h1`
    margin: 0;
    font-size: 21px;
    font-family: Roboto-Condensed,sans-serif;
    color: black;
    line-height: 30px;
	 @media (max-width: 600px) {
      font-size: 18px;
    };
  `,
  ContactsParagraph: styled.p`
    margin: 0;
    font-family: Roboto-Condensed,sans-serif;
    color: black;
    font-size: 19px;
	 @media (max-width: 600px) {
      font-size: 18px;
    };
    @media (max-width: 450px) {
      font-size: 17px;
    };
  `,
  ContactsData: styled.div`
    display: flex;
	  justify-content: center;
    min-width: 315px;
    line-height: 19px;
    margin: 0 0 5px;
  `,
  ContactsSociality: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-family: monospace;
    flex-wrap: wrap;
  `,
}