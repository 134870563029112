import { OpenCurrentPrices } from "./types";
import {FETCH_PRICE, PRICES_ARE_OPEN} from "./actions/actionTypes";


export function openCurrentPrices({dispatch}: any) {
    return function (next: any) {
        return function (action: OpenCurrentPrices) {
            const {type, price} = action;
            if(type === PRICES_ARE_OPEN) {
                switch (price) {
                // heir
                    case 'Стрижки':
                        dispatch({type: FETCH_PRICE, price: 'haircuts'});
                        break;
                    case 'Укладки та зачіски':
                        dispatch({type: FETCH_PRICE, price: 'hairdo'});
                        break;
                    case 'Фарбування':
                        dispatch({type: FETCH_PRICE, price: 'coloring'});
                        break;
                    case 'Реконструкція волосся':
                        dispatch({type: FETCH_PRICE, price: 'hairReconstruction'});
                        break;
                    case 'Нарощення волосся':
                        dispatch({type: FETCH_PRICE, price: 'hairExtensions'});
                        break;
                // nails
                    case 'Ручки':
                        dispatch({type: FETCH_PRICE, price: 'hands'});
                        break;
                    case 'Ніжки':
                        dispatch({type: FETCH_PRICE, price: 'foots'});
                        break;
                    case 'Нарощення нігтів':
                        dispatch({type: FETCH_PRICE, price: 'nailsExtensions'});
                        break;
                // makeUp
                    case 'Макіяж':
                        dispatch({type: FETCH_PRICE, price: 'makeUp'});
                        break;
                    case 'Брови та вії':
                        dispatch({type: FETCH_PRICE, price: 'brows'});
                        break;
                    case 'Перманентний макіяж':
                        dispatch({type: FETCH_PRICE, price: 'permanentMakeUp'});
                        break;
                // cosmetology
                    case 'Доглядова косметологія':
                        dispatch({type: FETCH_PRICE, price: 'careCosmetology'});
                        break;
                    case 'Апаратна косметологія':
                        dispatch({type: FETCH_PRICE, price: 'apparatusCosmetology'});
                        break;
                    case 'Пілінги':
                        dispatch({type: FETCH_PRICE, price: 'pilings'});
                        break;
                    case "Ін'єкційна косметологія":
                        dispatch({type: FETCH_PRICE, price: 'injectionCosmetology'});
                        break;
                    case "Лікування акне elos-лазер":
                        dispatch({type: FETCH_PRICE, price: 'acneElos'});
                        break;
                    case 'Лазерна епіляція':
                        dispatch({type: FETCH_PRICE, price: 'laserRemoval'});
                        break;
                // body
                    case 'Масаж':
                        dispatch({type: FETCH_PRICE, price: 'massage'});
                        break;
                    case 'Обгортання':
                        dispatch({type: FETCH_PRICE, price: 'envelopment'});
                        break;
                    case 'Шугарінг':
                        dispatch({type: FETCH_PRICE, price: 'shugaring'});
                        break;
                    case 'Воскова депіляція':
                        dispatch({type: FETCH_PRICE, price: 'waxing'});
                        break;
                }
            }
            return next(action)
        }
    }
}
