import { createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

import rootReducer from "./reducers/rootReducer";
import rootSaga from "./sagas/rootSaga";
import {vereficateMiddleware} from "./vereficateMiddleware";
import { openCurrentButtons } from "./openCurrentButtonsMiddleware";
import { openCurrentPrices } from "./openCurrentPricesMiddleware";

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, compose(
  applyMiddleware(
      sagaMiddleware,
      vereficateMiddleware,
      openCurrentButtons,
      openCurrentPrices
  ),
	// (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
));
sagaMiddleware.run(rootSaga);

export default store;
