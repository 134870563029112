import { Theme, makeStyles } from "@material-ui/core/styles";

export const FormStyles = makeStyles<Theme>({
	root: {
		width: 'auto',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		flexWrap: 'wrap',
		'& .MuiDialog-paperWidthSm': {
			width: 650,
			margin: 0,
			maxWidth: 'none',
		},
		'& .MuiDialogContent-root': {
			overflowY: 'auto',
		},
		"@media (max-width: 815px)": {
			justifyContent: 'center',
			width: '100%',
			'& .MuiDialog-paperWidthSm': {
				width: '100%',
				borderRadius: 0,
				height: '100%',
				maxWidth: 'none',
				maxHeight: 'none',
			},
			'& .MuiDialogContent-root': {
				overflowY: 'auto',
			},
		},
	},
	customFormStyle: {
		width: '100%',
		'& label.Mui-focused': {
			color: '#B8860B',
		},
		'& .MuiOutlinedInput-root': {
			'&.Mui-focused fieldset': {
				borderColor: '#B8860B',
			},
		}
	},
	button: {
		'&:hover': {
			color: '#B8860B',
		},
		'&:active': {
			boxShadow: 'none',
			borderColor: '#B8860B',
		},
	},
});
