import {put, call} from 'redux-saga/effects';
import {
  FETCH_MAIN_ERROR,
  FETCH_MAIN_SUCCESSES
} from "../../actions/actionTypes";
import {toggleSpinerStatus, toggleToastStatus} from "../../actions/actions";
import {mainApi} from "../../api";

export function* getMain() {
  try {
    yield put(toggleSpinerStatus(true))
    const mainResponse = yield call(mainApi)
    yield put({type: FETCH_MAIN_SUCCESSES, payload: mainResponse})
    yield put(toggleSpinerStatus(false))
  } catch (e) {
    yield put({type: FETCH_MAIN_ERROR, payload: e.message})
    yield put(toggleSpinerStatus(false))
    yield put(
      toggleToastStatus({ variant: "error", toastText: `Щось пішло не так...` })
    );
  }
}