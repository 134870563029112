import React from "react";
import {useDispatch} from "react-redux";
import "../../../custom.css";
import Diamond from "./priceStyles";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import {
    clearPrices,
    toggleFormStatus, updateFormValues,
} from "../../../../store/actions/actions";
import {Divider, List, ListItem} from "@material-ui/core";

interface Props {
    prices: any;
}

const Price = ({prices}: Props) => {
    const dispatch = useDispatch();

    const handlePriceClose = () => {
        dispatch(clearPrices());
    };
    const openForm = () => {
        dispatch(toggleFormStatus(true));
    };

    return (
        <Diamond.Price>
            <Diamond.PriceHeader>
                <Button onClick={handlePriceClose} color="default">
                    <CloseIcon/>
                </Button>
            </Diamond.PriceHeader>
            <Diamond.PriceMain>
                {prices.map((item: any, index: number) => (
                    <div key={index}>
                        <List
                            component="nav"
                            style={{padding: 0}}
                            aria-label="mailbox folders"
                        >
                            {/*<ListItem button onClick={() => {*/}
                            {/*  openForm();*/}
                            {/*  dispatch(updateFormValues({*/}
                            {/*    name: "",*/}
                            {/*    phone: "",*/}
                            {/*    service: [],*/}
                            {/*    time: null,*/}
                            {/*    date: null,*/}
                            {/*    description: item.name,*/}
                            {/*  }))*/}
                            {/*}}>*/}
                            <ListItem>
                                {item ? (
                                    <Diamond.PriceElement>
                                        <div className="custom-price-item">
                                            <span className="custom-name">{item.name}</span>
                                            <span className="custom-price">{item.price}</span>
                                        </div>
                                        <div className="custom-price-item">
                                            {item.oneMoreItem ? (
                                                <span className="custom-plus">{item.oneMoreItem}</span>
                                            ) : null}
                                            {item.oneMorePrice ? (
                                                <span className="custom-plus-price">
                          {item.oneMorePrice}
                        </span>
                                            ) : null}
                                        </div>
                                        <div className="custom-price-item">
                                            {item.twoMoreItem ? (
                                                <span className="custom-plus">{item.twoMoreItem}</span>
                                            ) : null}
                                            {item.twoMorePrice ? (
                                                <span className="custom-plus-price">
                          {item.twoMorePrice}
                        </span>
                                            ) : null}
                                        </div>
                                        <div className="custom-price-item">
                                            {item.threeMoreItem ? (
                                                <span className="custom-plus">
                          {item.threeMoreItem}
                        </span>
                                            ) : null}
                                            {item.threeMorePrice ? (
                                                <span className="custom-plus-price">
                          {item.threeMorePrice}
                        </span>
                                            ) : null}
                                        </div>
                                        <div className="custom-price-item">
                                            {item.fourMoreItem ? (
                                                <span className="custom-plus">{item.fourMoreItem}</span>
                                            ) : null}
                                            {item.fourMorePrice ? (
                                                <span className="custom-plus-price">
                          {item.fourMorePrice}
                        </span>
                                            ) : null}
                                        </div>
                                        <div className="custom-price-item">
                                            {item.fifeMoreItem ? (
                                                <span className="custom-plus">{item.fifeMoreItem}</span>
                                            ) : null}
                                            {item.fifeMorePrice ? (
                                                <span className="custom-plus-price">
                          {item.fifeMorePrice}
                        </span>
                                            ) : null}
                                        </div>
                                        <div className="custom-price-item">
                                            {item.sixMoreItem ? (
                                                <span className="custom-plus">{item.sixMoreItem}</span>
                                            ) : null}
                                            {item.sixMorePrice ? (
                                                <span className="custom-plus-price">
                          {item.sixMorePrice}
                        </span>
                                            ) : null}
                                        </div>
                                    </Diamond.PriceElement>
                                ) : null}
                            </ListItem>
                        </List>
                        <Divider/>
                    </div>
                ))}
            </Diamond.PriceMain>
        </Diamond.Price>
    );
};

export default Price;
