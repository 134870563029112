import {AboutInterface, DataInterface, FormValues, HeaderData, MainActionTypes, MainInterface,} from "../../types";
import {
  CLEAR_PRICE,
  FETCH_ABOUT,
  FETCH_ABOUT_ERROR,
  FETCH_ABOUT_SUCCESSES,
  FETCH_DISCOUNT,
  FETCH_DISCOUNT_ERROR,
  FETCH_DISCOUNT_SUCCESSES,
  FETCH_HEADER,
  FETCH_HEADER_ERROR,
  FETCH_HEADER_SUCCESSES,
  FETCH_MAIN,
  FETCH_MAIN_ERROR,
  FETCH_MAIN_SUCCESSES,
  FETCH_PRICE,
  FETCH_PRICE_ERROR,
  FETCH_PRICE_SUCCESSES,
  SET_BUTTONS_NAME,
  UPDATE_FORM_VALUES,
} from "../../actions/actionTypes";

export interface storeState {
  discounts: DataInterface[];
  headers: HeaderData[];
  priceButtonsName: string[];
  prices: any;
  about: AboutInterface;
  formValues: FormValues;
  mainData: MainInterface;
}

const initialState: storeState = {
  discounts: [],
  headers: [],
  priceButtonsName: [],
  prices: [],
  about: {
    address: "",
    image: "",
    location: "",
    phone: "",
    schedule: "",
    scheduleHoliday: "",
  },
  formValues: {
    name: "",
    phone: "",
    service: [],
    time: null,
    date: null,
    description: "",
  },
  mainData: {
    hair: {
      image: '',
      text: ''
    },
    nails: {
      image: '',
      text: ''
    },
    face: {
      image: '',
      text: ''
    },
    cosmetology: {
      image: '',
      text: ''
    },
    body: {
      image: '',
      text: ''
    },
  }
};

const MainReducer = (
  state = initialState,
  action: MainActionTypes
): storeState => {
  switch (action.type) {
    case UPDATE_FORM_VALUES:
      return {
        ...state,
        formValues: action.payload,
      };
    case SET_BUTTONS_NAME:
      return {
        ...state,
        priceButtonsName: action.payload,
      };
    case FETCH_ABOUT:
      return {
        ...state,
      };
    case FETCH_ABOUT_SUCCESSES:
      return {
        ...state,
        about: action.payload,
      };
    case FETCH_ABOUT_ERROR:
      return {
        ...state,
        about: state.about,
      };
    case FETCH_DISCOUNT:
      return {
        ...state,
      };
    case FETCH_DISCOUNT_SUCCESSES:
      return {
        ...state,
        discounts: action.payload,
      };
    case FETCH_DISCOUNT_ERROR:
      return {
        ...state,
        discounts: [],
      };
    case FETCH_HEADER:
      return {
        ...state,
      };
    case FETCH_HEADER_SUCCESSES:
      return {
        ...state,
        headers: action.payload,
      };
    case FETCH_HEADER_ERROR:
      return {
        ...state,
        headers: [],
      };
    case FETCH_PRICE:
      return {
        ...state,
      };
    case FETCH_PRICE_SUCCESSES:
      return {
        ...state,
        prices: action.payload,
      };
    case FETCH_PRICE_ERROR:
      return {
        ...state,
        prices: [],
      };



    case FETCH_MAIN:
      return {
        ...state,
      };
    case FETCH_MAIN_SUCCESSES:
      return {
        ...state,
        mainData: action.payload,
      };
    case FETCH_MAIN_ERROR:
      return {
        ...state,
        mainData: initialState.mainData,
      };



    case CLEAR_PRICE:
      return {
        ...state,
        prices: [],
      };
    default:
      return state;
  }
};

export default MainReducer;
