import React from "react";
import '../../../custom.css';

const Spinner = () => {
    return (
        <div className='spinner-container'>
            <div className="loadingio-spinner-eclipse-k5otvilyroo">
                <div className="ldio-hpigofqkvpq">
                    <div/>
                </div>
            </div>
        </div>

    )
};

export default Spinner;
