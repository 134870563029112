import { makeStyles } from "@material-ui/core/styles";

export const menuStyles = makeStyles({
  servicePopup: {
    position: "absolute",
    width: "200px",
    height: "auto",
    left: 0,
    top: "80px",
    zIndex: 2,
    "@media (max-width: 815px)": {
      top: "50px",
    },
  },
  root: {
    minWidth: 350,
    height: 80,
    background: "transparent",
    border: "none",
    boxShadow: "none",
    position: "relative",
    "@media (max-width: 815px)": {
      height: 50,
    },
    "@media (max-width: 550px)": {
      minWidth: 300,
    },
    "& .MuiTab-root": {
      minWidth: 160,
      minHeight: 80,
      padding: "0 0",
      fontFamily: "Roboto-Condensed, sans-serif",
      fontSize: "21px",
      fontWeight: 600,
      "&:hover": {
        color: "#B8860B",
        background: "#b8860b17",
      },
      "@media (max-width: 815px)": {
        minHeight: 50,
      },
      "@media (max-width: 650px)": {
        minWidth: 140,
        fontSize: "21px",
      },
      "@media (max-width: 570px)": {
        minWidth: 110,
        fontSize: "18px",
      },
      "@media (max-width: 450px)": {
        minWidth: 125,
      },
      "@media (max-width: 370px)": {
        minWidth: 102,
        fontSize: "16px",
      },
      "&:last-child": {
        "@media (max-width: 450px)": {
          display: "none",
        },
      },
    },
    "& .MuiTabs-root": {
      minHeight: 80,
      color: "black",
      "@media (max-width: 815px)": {
        minHeight: 50,
      },
    },
    "& .Mui-selected": {
      color: "#B8860B",
    },
    "& .MuiTabs-indicator": {
      background: "#B8860B",
    },
  },
});
