import React from "react";
import MaskedInput from "react-text-mask";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from "@material-ui/core/FormControl";
import {FormStyles} from "../field/FormStyles";

interface Props {
	value: string;
    name: string;
    placeholder: string;
    changeInputHandler: (value: string, name: string) => void;
}

export default function PhoneField({value, name, placeholder, changeInputHandler}: Props) {
	const classes = FormStyles();

  return (
    <React.Fragment>
      <FormControl className={classes.customFormStyle}>
        <OutlinedInput
			 value={value}
          onChange={(event) => changeInputHandler(event.target.value, name)}
          name={name}
          id="formatted-text-mask-input"
          inputComponent={TextMaskCustom as any}
          placeholder={placeholder}
        />
      </FormControl>
    </React.Fragment>
  );
}


interface TextMaskCustomProps {
    inputRef: (ref: HTMLInputElement | null) => void;
  }
  
  function TextMaskCustom(props: TextMaskCustomProps) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={(ref: any) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[
          "(",
          /[0-9]/,
          /\d/,
          /\d/,
          ")",
          " ",
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/
        ]}
        placeholderChar={"\u2000"}
      />
    );
  }
