import React from "react";
import { Button } from "@material-ui/core";
import CSS from "csstype";

interface Props {
  variant?: "text" | "outlined" | "contained" | undefined;
  color?: "inherit" | "primary" | "secondary" | "default" | undefined;
  style?: CSS.Properties;
  textBtn?: string;
  clickFunc?: (arg0?: number) => void;
  index?: number;
}

const CustomButton = ({
  clickFunc,
  index,
  variant,
  color,
  style,
  textBtn,
}: Props) => {
  return (
    <Button
      variant={variant}
      color={color}
      style={style}
      onClick={() => clickFunc!(index)}
    >
      {textBtn}
    </Button>
  );
};

export default CustomButton;
