import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {ToastInterface} from "../../../../store/types";
import {useDispatch} from "react-redux";
import {toggleToastStatus} from "../../../../store/actions/actions";

interface Props {
    toast: ToastInterface;
}

export default function Toast({toast}: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(toggleToastStatus({variant: undefined, toastText: ''}))
    };
    return (
        <div className={classes.root}>
            <Snackbar
                open={!!toast.toastText}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                {
                    toast.toastText
                    ?  <Alert onClose={handleClose} severity={toast?.variant}>
                            {toast?.toastText}
                      </Alert>
                    : null
                }
            </Snackbar>
        </div>
    );
}



function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));
