import styled from "styled-components";

export default {
  Discount: styled.div`
    height: 200px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    border: 2px solid #B8860B;
    padding: 0 5px;
  `,
};
