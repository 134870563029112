import {takeLatest} from "redux-saga/effects";
import {getDiscount} from "./discountSaga/discountSaga";
import {
  FETCH_DISCOUNT,
  FETCH_HEADER,
  SENDING_MESSAGE,
  FETCH_PRICE,
  FETCH_ABOUT,
  FETCH_MAIN,
} from "../actions/actionTypes";
import {getHeader} from "./headerSaga/headerSaga";
import {sendMessage} from "./registrationSaga/registrationSaga";
import {getPrice} from "./pricesSaga/pricesSaga";
import {getAbout} from "./aboutSaga/aboutSaga";
import {getMain} from "./mainSaga/mainSaga";


function* discountWatcher() {
  yield takeLatest(FETCH_DISCOUNT, getDiscount);
  yield takeLatest(FETCH_HEADER, getHeader);
  yield takeLatest(SENDING_MESSAGE, sendMessage);
  yield takeLatest(FETCH_PRICE, getPrice);
  yield takeLatest(FETCH_ABOUT, getAbout);
  yield takeLatest(FETCH_MAIN, getMain);

}

export default discountWatcher;
