import { call, put } from "redux-saga/effects";
import { discountApi } from "../../api";
import {
  FETCH_DISCOUNT_SUCCESSES,
  FETCH_DISCOUNT_ERROR
} from "../../actions/actionTypes";
import {toggleSpinerStatus, toggleToastStatus} from "../../actions/actions";

export function* getDiscount() {
  try {
    yield put(toggleSpinerStatus(true));
    const response = yield call(() => discountApi());
    const responseArr = yield Object.values(response);
    yield put({ type: FETCH_DISCOUNT_SUCCESSES, payload: responseArr });
    yield put(toggleSpinerStatus(false));
  } catch (error) {
    yield put({ type: FETCH_DISCOUNT_ERROR, payload: error.message });
    yield put(toggleSpinerStatus(false));
    yield put(toggleToastStatus({variant: 'error', toastText: `Щось пішло не так...`}));
  }
}
