import styled from "styled-components";

export default {
  MenuBlock: styled.div`
    width: 100%;
    height: 80px;
	  @media (max-width: 815px) {
		  height: 50px;
	  },
  `,
  Menu: styled.div`
    box-shadow: 0px 0px 22px 0px black;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white;
    z-index: 2;
    width: 100%;
    height: 80px;
    display: flex;
    position: fixed;
	 @media (max-width: 815px) {
		height: 50px;
	 },
  `,
  Sociality: styled.div`
    width: 120px;
    height: 60px;
    position: absolute;
    right: 0px;
    top: 27px;
    z-index: 1;
    @media (max-width: 900px) {
      display: none;
    }
  `,
};
