import styled, { keyframes } from "styled-components";

interface Props {
  isOpenScrollButton?: boolean;
}
const position = keyframes`
  0% {
    left: -600px;
    top: -600px;
  }
  100% {
    left: 0px;
    top: 0px;
  }
  `;

export default {
  Modal: styled.div`
    position: fixed;
    height: 90vh;
    z-index: 1;
    right: 0px;
    bottom: 0px;
    color: red;
  `,
  PriceItem: styled.div`
    height: 40px;
    width: 100%;
    margin: 5px 0;
    background: blue;
  `,
  Price: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-eventy;
    width: 100%;
    height: auto;
    height: 100%;
    background: white;
    position: absolute;
    left: -600px;
    top: -600px;
    z-index: 1;
    transition: 1s;
    animation: ${position} 0.5s ease-in-out forwards;
  `,
  PriceMain: styled.main`
    height: 100%;
    display: flex;
    flex-direction: column;
  `,
  PriceHeader: styled.header`
    display: flex;
    justify-content: flex-end;
    margin: 5px 5px 0 0;
  `,
  PriceElement: styled.div`
    min-height: 50px;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
};
