import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { carouselStyles } from "./carouselStyled";
import { DataInterface, HeaderData } from "../../../../store/types";


interface Props {
  steps: Array<DataInterface | HeaderData> | undefined;
  interval: number;
  styled?: any;
  swipe: boolean;
}

const Carousel = ({steps, interval, styled, swipe}: Props) => {
  const classes =  carouselStyles(styled);

  const settings = {
    dots: swipe,
    fade: true,
    infinite: true,
    swipe: swipe,
    touchMove: swipe,
    swipeToSlide: swipe,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: interval,
  }

  return (
    <div className={classes.root}>
        <Slider {...settings}>
          {steps && !!steps.length 
            ?  steps?.map((step: any, index: number) => (
                step.image 
                  ? <React.Fragment key={index}>
                      <img
                        key={index}
                        className={classes.img}
                        src={step.image}
                        alt={step.text}
                      />
                      <h2>{step.text}</h2>
                    </React.Fragment>
                  : <h2 className={classes.discount} key={index}>{step.text}</h2>
            )) : null}
        </Slider>
    </div>
  );
}

export default Carousel;
