import axios from "axios";
import {SendMessage} from "./types";

const api = "https://diamond-lviv.firebaseio.com/";
const sendApi = "https://diamon-beauty-studio-73920aa35e99.herokuapp.com/api/sendEmailMessage";

const discountApi = async () => {
  try {
    const fetchingDiscount = await axios.get(`${api}discount/.json`);
    return await fetchingDiscount.data;
  } catch (error) {
    throw error;
  }
};

const aboutApi = async () => {
  try {
    const fetchingPhilosophy = await axios.get(`${api}about/.json`);
    return await fetchingPhilosophy.data;
  } catch (error) {
    throw error;
  }
};

const pricesApi = async (price: string) => {
  try {
    const fetchingPrices = await axios.get(`${api}prices/${price}/.json`);
    return await fetchingPrices.data;
  } catch (error) {
    throw error;
  }
};

const headerApi = async () => {
  try {
    const fetchingHeader = await axios.get(`${api}header/.json`);
    return await fetchingHeader.data;
  } catch (error) {
    throw error;
  }
};

const mainApi = async () => {
  try {
    const fetchingMain = await axios.get(`${api}main/.json`);
    return await fetchingMain.data;
  } catch (e) {
    return e;
  }
};

const messageApi = (sendMessage: SendMessage) => {
  try {
    return fetch(sendApi, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(sendMessage)
    });
  } catch (e) {
    return e
  }
};

export { discountApi, headerApi, messageApi, pricesApi, aboutApi, mainApi };
