import styled, {keyframes} from "styled-components";

const opacity = keyframes`
0% {
  transform: scale(0) ;
  opacity: 0;
}
50% {
  transform: scale(1.03) ;
  opacity: 50;
}
100% {
  transform: scale(1) ;
  opacity: 100;
}
`;

export default {
  ServiceCont: styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
  `,
  Title: styled.p`
    color: #b8860b;
    font-size: 22px;
    font-family: Roboto-Condensed,sans-serif;
    margin: 0;
    line-height: 25px;
  `,
  Paragraph: styled.p`
    color: black;
    font-size: 22px;
    margin: 0;
    font-family: sans-serif;
    tet-transform: uppercase;
  `,
  ServiceContainer: styled.div`
    min-height: 620px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    @media (min-width: 1600px) {
      min-height: 810px;
    }
    @media (max-width: 1200px) {
      min-height: 570px;
    }
    @media (max-width: 1150px) {
      min-height: 470px;
    }
    @media (max-width: 900px) {
      min-height: 390px;
    }
    @media (max-width: 700px) {
      min-height: 320px;
    }
    @media (max-width: 600px) {
      min-height: 285px;
    }
    @media (max-width: 500px) {
      min-height: 730px;
      flex-direction: column;
    }
    &:nth-child(1) {
      & > div:nth-child(1) {
        border: 2px solid #b8860b;
        animation: ${opacity} 3s ease-in-out 0.5s forwards;
        & > p {
          color: black;
        }
        @media (max-width: 500px) {
          order: 1;
        }
      }
      & > div:nth-child(2) {
        animation: ${opacity} 3s ease-in-out forwards;
      }
    }
    &:nth-child(2) {
      & > div:nth-child(1) {
        animation: ${opacity} 3s ease-in-out forwards;
      }
      & > div:nth-child(2) {
        border: 2px solid #b8860b;
        animation: ${opacity} 3s ease-in-out 0.5s forwards;
        & > p {
          color: black;
        }
      }
    }
    &:nth-child(3) {
      & > div:last-child {
        animation: ${opacity} 3s ease-in-out forwards;
      }
      & > div:first-child {
        background: #2d2c2c;
        border: 2px solid #b8860b;

        animation: ${opacity} 3s ease-in-out 0.5s forwards;
        @media (max-width: 500px) {
          order: 1;
        }
      }
    }
    &:nth-child(4) {
      & > div:nth-child(2) {
        border: 2px solid #b8860b;
        animation: ${opacity} 3s ease-in-out 0.5s forwards;
        & > p {
          color: black;
        }
        @media (max-width: 500px) {
          order: 1;
        }
      }
      & > div:nth-child(1) {
        animation: ${opacity} 3s ease-in-out forwards;
      }
    }
    &:nth-child(5) {
      & > div:nth-child(2) {
        animation: ${opacity} 3s ease-in-out forwards;
      }
      & > div:first-child {
        border: 2px solid #b8860b;
        animation: ${opacity} 3s ease-in-out 0.5s forwards;
        background: #2d2c2c;
        @media (max-width: 500px) {
          order: 1;
        }
      }
    }
  `,
  Service: styled.div`
    box-sizing: border-box;
    width: 49%;
    height: 600px;
    background: white;
    opacity: 0;
    transition: 1s;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (min-width: 1600px) {
      height: 800px;
      width: 49%;
    }
    @media (max-width: 1200px) {
      height: 550px;
    }
    @media (max-width: 1150px) {
      height: 450px;
    }
    @media (max-width: 900px) {
      height: 370px;
    }
    @media (max-width: 700px) {
      height: 300px;
    }
    @media (max-width: 600px) {
      height: 265px;
    }
    @media (max-width: 500px) {
      height: 350px;
      width: 100%;
    }
    & > p {
      font-family: Roboto-Condensed,sans-serif;
      letter-spacing: 1px;
      line-height: 23px;
      font-size: 21px;
      margin: 0 0 20px 0;
      text-align: center;
      max-width: 80%;
      color: white;
      @media (max-width: 900px) {
        font-size: 24px;
      }
      @media (max-width: 700px) {
        font-size: 14px;
        margin: 0 0 10px 0;
      }
      @media (max-width: 500px) {
        font-size: 19px;
      }
    }
    & > h3 {
      width: 100%;
      text-align: center;
      font-family: "Herr Von Muellerhoff", cursive;
      font-weight: 500;
      padding: 0 5px;
      color: #b8860b;
      font-size: 6em;
      margin: 0 0 5px 0;
      @media (max-width: 900px) {
        font-size: 4.5em;
      }
      @media (max-width: 700px) {
        font-size: 3.5em;
      }
      @media (max-width: 500px) {
        font-size: 4em;
      }
    }
  `,
};
