import React, { Fragment } from "react";
import {TimePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from '@material-ui/core/styles';
import ukLocale from "date-fns/locale/uk";
import {FormStyles} from "../field/FormStyles";

interface Props {
  name: string;
  label: string;
  changeInputHandler: (value: string, name: string) => void;
  time: Date | null;
}

function TimeRangePicker({ name, label, changeInputHandler, time }: Props) {
	const classes = FormStyles();
  return (
    <Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ukLocale}>
        <ThemeProvider theme={defaultMaterialTheme}>
          <TimePicker
			   className={classes.customFormStyle}
            margin="normal"
            id="time-picker"
            ampm={false}
            label={label}
            value={time}
            inputVariant="outlined"
            onChange={(event: any) => changeInputHandler(event, name)}
            name={name}
            style={{ margin: 0 }}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </Fragment>
  );
}

const defaultMaterialTheme = createTheme({
	palette: {
		primary: {
			main: '#B8860B',
		},
	},
});

export default TimeRangePicker;
