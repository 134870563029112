import {FormValues, ToastInterface} from "../types";
import {
    TOGGLE_SPINNER,
    TOGGLE_FORM,
    TOGGLE_TOAST,
    TOGGLE_PRICE,
    SET_BUTTONS_NAME,
    CLEAR_PRICE,
    UPDATE_FORM_VALUES
} from "./actionTypes";

export const setButtonsPrice = (arr: string[]) => ({
    type: SET_BUTTONS_NAME,
    payload: arr,
});

export const updateFormValues = (object: FormValues) => ({
    type: UPDATE_FORM_VALUES,
    payload: object,
});

export const clearPrices = () => ({
    type: CLEAR_PRICE,
});

export const toggleSpinerStatus = (value: boolean) => ({
    type: TOGGLE_SPINNER,
    payload: value,
});

export const toggleFormStatus = (value: boolean) => ({
    type: TOGGLE_FORM,
    payload: value,
});

export const toggleToastStatus = (data: ToastInterface) => ({
    type: TOGGLE_TOAST,
    payload: data,
});

export const togglePriceStatus = (value: boolean) => ({
    type: TOGGLE_PRICE,
    payload: value,
});
