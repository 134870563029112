import { VERIFICATION_MESSAGE, SENDING_MESSAGE } from "./actions/actionTypes";
import { VerificationData } from "./types";
import { toggleToastStatus } from "./actions/actions";

export function vereficateMiddleware({ dispatch }: any) {
  return function (next: any) {
    return function (action: VerificationData) {
      if (action.type === VERIFICATION_MESSAGE) {
        const {
          name,
          time,
          date,
          description,
          phone,
          service,
        } = action.sendMessage;
        let sendMessage = {
          name,
          phone,
          service,
          desiredTime: "Не вказано",
          description: description || "Немає",
        };

        if (!name || !phone || !service.length) {
          dispatch(
            toggleToastStatus({
              variant: "warning",
              toastText: `Заповніть поля позначені *`,
            })
          );
          return;
        }

        if ((date && date.getDay() !== 0 && time && time.getHours() >= 20)
          || (date && date.getDay() !== 0 && time && time.getHours() < 9)) {
          dispatch(
            toggleToastStatus({
              variant: "info",
              toastText: `Ми працюємо: Понеділок-Субота: 9:00 - 20:00`,
            })
          );
          return;
        }

        if ((date && date.getDay() === 0 && time && time.getHours() >= 18)
          || (date && date.getDay() === 0 && time && time.getHours() < 10)) {
          dispatch(
            toggleToastStatus({
              variant: "info",
              toastText: `Ми працюємо: Неділя та Свята: 10:00 - 18:00`,
            })
          );
          return;
        }

        if (date && !time) {
          const currentDate = `${date.getUTCDate()}/${
            date.getUTCMonth() + 1
          }/${date.getFullYear()}`;
          sendMessage = {
            ...sendMessage,
            desiredTime: currentDate,
          };
          dispatch(toggleToastStatus({ variant: undefined, toastText: "" }));
          dispatch({ type: SENDING_MESSAGE, sendMessage });
          return;
        }

        if (date && time) {
          const currentDate = `${date.getUTCDate()}/${
            date.getUTCMonth() + 1
          }/${date.getFullYear()}`;
          const currentTime = `${time.getHours()}:${time.getMinutes()}`;
          sendMessage = {
            ...sendMessage,
            desiredTime: currentDate + " " + currentTime,
          };
          dispatch(toggleToastStatus({ variant: undefined, toastText: "" }));
          dispatch({ type: SENDING_MESSAGE, sendMessage });
          return;
        }

        if (phone && phone.trim().length < 14) {
          dispatch(
            toggleToastStatus({
              variant: "warning",
              toastText: `Не правильний номер телефону`,
            })
          );
          return;
        }

        dispatch(toggleToastStatus({ variant: undefined, toastText: "" }));
        dispatch({ type: SENDING_MESSAGE, sendMessage });
      }
      return next(action);
    };
  };
}
