import {AppActionTypes, ToastInterface} from "../../types";
import {TOGGLE_SPINNER, TOGGLE_FORM, TOGGLE_TOAST, TOGGLE_PRICE} from "../../actions/actionTypes";

export interface AppReducer {
  isSpinner: boolean;
  isForm: boolean;
  isPrice: boolean;
  toast: ToastInterface;
}

const initialState = {
  isSpinner: false,
  isForm: false,
  isPrice: false,
  toast: {
      variant: undefined,
      toastText: undefined,
  },
};

export const appReducer = (
  state = initialState,
  action: AppActionTypes
): any => {
  switch (action.type) {
    case TOGGLE_SPINNER:
      return {
          ...state,
          isSpinner: action.payload,
      };
    case TOGGLE_FORM:
      return {
          ...state,
          isForm: action.payload,
      };
    case TOGGLE_PRICE:
      return {
          ...state,
          isPrice: action.payload,
      };
    case TOGGLE_TOAST:
      return {
          ...state,
          toast: {
              variant: action.payload.variant,
              toastText: action.payload.toastText,
          }
      };
    default:
      return state;
  }
};
