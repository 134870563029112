import { Theme, makeStyles } from "@material-ui/core/styles";

export const PriceStyles = makeStyles<Theme>({
        root: {
            width: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
			  '& .MuiDialog-paperWidthSm': {
				  width: 650,
				  margin: 0,
				  maxHeight: 'none',
				  maxWidth: 'none',
				  height: '525px',
			  },
			  '& .MuiDialogContent-root': {
            	overflowY: 'hidden',
			  },
            "@media (max-width: 815px)": {
            	justifyContent: 'center',
					width: '100%',
					'& .MuiDialog-paperWidthSm': {
						width: '100%',
						height: '100%',
						maxWidth: 'none',
						maxHeight: 'none',
					},
					'& .MuiDialogContent-root': {
            		padding: 0,
						overflowY: 'auto',
					},
            },
			  "@media (max-width: 500px)": {
				  '& .MuiDialog-paperWidthSm': {
					  width: '100%',
					  height: '100%',
					  borderRadius: 0,
				  },
			  },
        },
});
