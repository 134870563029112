import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

export const ButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 1,
      display: 'flex',
      justifyContent: 'center',
      flexWrap: "wrap",
      width: '250px',
      height: '155px',
	   minHeight: '60px',
      "@media (max-width: 610px)": {
        width: '100%',
        minWidth: 250,
        height: '170px',
      },
    },
    image: {
      width: '250px',
      height: '170px',
      position: "relative",
      "@media (max-width: 610px)": {
        width: '100%',
        height: '170px',
      },
      "&:hover, &$focusVisible": {
        zIndex: 1,
        "& $imageBackdrop": {
          opacity: 0.15
        },
        "& $imageMarked": {
          opacity: 0,
          background: "#b8860b"
        },
        "& $imageTitle": {
          border: "2px solid #b8860b"
        }
      }
    },
    focusVisible: {},
    imageButton: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: '#b8860b'
    },
    imageSrc: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: "cover",
      backgroundPosition: "center 40%"
    },
    imageBackdrop: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: "transparent",
      opacity: 0.4,
      transition: theme.transitions.create("opacity")
    },
    imageTitle: {
      position: "relative",
      padding: '30px 20px',
      maxWidth: '180px',
      width: '180px',
    },
    imageMarked: {
      height: 3,
      width: 18,
      backgroundColor: '#b8860b',
      position: "absolute",
      bottom: -2,
      left: "calc(50% - 9px)",
      transition: theme.transitions.create("opacity")
    },
    title: {
      color: '#b8860b',
      fontSize: 22,
      fontFamily: 'Roboto-Condensed',
      margin: 0,
      lineHeight: '25px',
    }
  })
);
