import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

export const popupStyled = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
		 "& .MuiListItem-button": {
			 '&:hover': {
			 	color: '#B8860B',
			   backgroundColor: "#b8860b1a",
			 },
		 },
		 '& .MuiTypography-displayBlock': {
			 fontFamily: 'Roboto-Condensed',
			 fontSize: '21px',
			 lineHeight: '22px',
		 },
    },
  }),
);
