export const TOGGLE_SPINNER = 'TOGGLE_SPINNER';
export const TOGGLE_FORM = 'TOGGLE_FORM';
export const TOGGLE_TOAST = 'TOGGLE_TOAST';
export const TOGGLE_PRICE = 'TOGGLE_PRICE';

export const CLEAR_PRICE = 'CLEAR_PRICE';
export const UPDATE_FORM_VALUES = 'UPDATE_FORM_VALUES';
export const SET_BUTTONS_NAME = 'SET_BUTTONS_NAME';
export const VERIFICATION_MESSAGE = 'VERIFICATION_MESSAGE';

export const BUTTONS_ARE_OPEN = 'BUTTONS_ARE_OPEN';
export const PRICES_ARE_OPEN = 'PRICES_ARE_OPEN';

export const FETCH_DISCOUNT = 'FETCH_DISCOUNT';
export const FETCH_DISCOUNT_SUCCESSES = 'FETCH_DISCOUNT_SUCCESSES';
export const FETCH_DISCOUNT_ERROR = 'FETCH_DISCOUNT_ERROR';

export const FETCH_HEADER = 'FETCH_HEADER';
export const FETCH_HEADER_SUCCESSES = 'FETCH_HEADER_SUCCESSES';
export const FETCH_HEADER_ERROR = 'FETCH_HEADER_ERROR';

export const SENDING_MESSAGE = 'SENDING_MESSAGE';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'SEND_MESSAGE_ERROR';

export const FETCH_PRICE = 'FETCH_PRICE';
export const FETCH_PRICE_SUCCESSES = 'FETCH_PRICE_SUCCESSES';
export const FETCH_PRICE_ERROR = 'FETCH_PRICE_ERROR';

export const FETCH_ABOUT = 'FETCH_ABOUT';
export const FETCH_ABOUT_SUCCESSES = 'FETCH_ABOUT_SUCCESSES';
export const FETCH_ABOUT_ERROR = 'FETCH_ABOUT_ERROR';

export const FETCH_MAIN = 'FETCH_MAIN';
export const FETCH_MAIN_SUCCESSES = 'FETCH_MAIN_SUCCESSES';
export const FETCH_MAIN_ERROR = 'FETCH_MAIN_ERROR';