import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grow from "@material-ui/core/Grow";
import { menuStyles } from "./menuStyled";

import { useDispatch } from "react-redux";

import PopupItems from "../popupItems/PopupItems";
import { toggleFormStatus } from "../../../../store/actions/actions";

const MainMenu = () => {
  const classes = menuStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState<number>(1);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Paper square className={classes.root} id="menu">
      {isPopupOpen ? (
        <Grow in={isPopupOpen} timeout={{ enter: 500, exit: 1000 }}>
          <div className={classes.servicePopup}>
            <PopupItems setIsPopupOpen={setIsPopupOpen} />
          </div>
        </Grow>
      ) : null}
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="secondary"
        aria-label="icon label tabs example"
      >
        <Tab
          onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
            isPopupOpen ? setIsPopupOpen(false) : setIsPopupOpen(true)
          }
          label="Послуги"
        />
        {/*<Tab*/}
        {/*  onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {*/}
        {/*    setIsPopupOpen(false);*/}
        {/*    dispatch(toggleFormStatus(true));*/}
        {/*  }}*/}
        {/*  label="Записатись"*/}
        {/*/>*/}
        <Tab
          onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            const about = document.getElementById("about-block-id");
            about!.scrollIntoView({ block: "center" });
            setIsPopupOpen(false);
          }}
          label="Про нас"
        />
        <Tab
          onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            const contacts = document.getElementById("contacts-block-id");
            contacts!.scrollIntoView({ block: "center" });
            setIsPopupOpen(false);
          }}
          label="Контакти"
        />
      </Tabs>
    </Paper>
  );
};

export default MainMenu;
