import { makeStyles, createStyles } from "@material-ui/core/styles";

export const IconStyles = makeStyles(() =>
	createStyles({
		button: {
			color: 'black',
			'&:hover': {
				background: "#b8860b1a",
				color: '#B8860B',
			},
			"& .MuiTouchRipple-root": {
				color: '#B8860B',
			},
		},
	})
);
