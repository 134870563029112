import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { popupStyled } from "./popupStyled";

interface Props {
  setIsPopupOpen: (value: boolean) => void;
}

const PopupItems = ({ setIsPopupOpen }: Props) => {
  const classes = popupStyled();

  return (
    <List
      onClick={() => setIsPopupOpen(false)}
      component="nav"
      className={classes.root}
      aria-label="mailbox folders"
    >
      <ListItem
        button
        onClick={() => {
          const hair = document.getElementById("hair-block-id");
          hair!.scrollIntoView({ block: "center" });
        }}
      >
        <ListItemText primary="Волосся" />
      </ListItem>
      <Divider light />
      <ListItem
        button
        onClick={() => {
          const nail = document.getElementById("nail-block-id");
          nail!.scrollIntoView({ block: "center" });
        }}
      >
        <ListItemText primary="Нігті" />
      </ListItem>
      <Divider light />
      <ListItem
        button
        onClick={() => {
          const face = document.getElementById("face-block-id");
          face!.scrollIntoView({ block: "center" });
        }}
      >
        <ListItemText primary="Лице" />
      </ListItem>
      <Divider light />
      <ListItem
        button
        onClick={() => {
          const cosmetology = document.getElementById("cosmetology-block-id");
          cosmetology!.scrollIntoView({ block: "center" });
        }}
      >
        <ListItemText primary="Косметологія" />
      </ListItem>
      <Divider light />
      <ListItem
        button
        onClick={() => {
          const body = document.getElementById("body-block-id");
          body!.scrollIntoView({ block: "center" });
        }}
      >
        <ListItemText primary="Тіло" />
      </ListItem>
    </List>
  );
};

export default PopupItems;
