import React from "react";
import Diamond from "./discountStyles";

import { connect } from "react-redux";
import { DataInterface } from "../../../../store/types";
import { storeState } from "../../../../store/reducers/mainReducer/MainReducer";
import Carousel from "../../atoms/carousel/Carousel";

interface Props {
  discounts: DataInterface[];
}

const DiscountSection = ({ discounts }: Props) => {
  return (
    <React.Fragment>
      {discounts && !!discounts.length
        ? <Diamond.Discount>
            <Carousel
              steps={discounts}
              swipe={true}
              interval={5000}
              styled={{ height: 200, maxHeight: 200 }}
            />
          </Diamond.Discount>
        : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state: { main: storeState }) => ({
  discounts: state.main.discounts
});

export default connect(mapStateToProps)(DiscountSection);
