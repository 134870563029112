import React from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import { ButtonStyles } from "./buttonStyles";

interface Props {
  title: string;
  clickFunk?: (arg0?: string) => void;
}

const ButtonPrice = ({ title, clickFunk }: Props) => {
  const classes = ButtonStyles();

  return (
    <div className={classes.root}>
      <ButtonBase
        focusRipple
        key={"Записатись"}
        className={classes.image}
        focusVisibleClassName={classes.focusVisible}
        onClick={() => clickFunk!(title!)}
      >
        <span className={classes.imageBackdrop} />
        <span className={classes.imageButton}>
          <Typography
            component="span"
            variant="body1"
            color="secondary"
            className={classes.imageTitle}
          >
            <p className={classes.title}>{title}</p>
            <span className={classes.imageMarked} />
          </Typography>
        </span>
      </ButtonBase>
    </div>
  );
};

export default ButtonPrice;
