import React from "react";
import Diamond from "./headerStyles";

import Carousel from "../../atoms/carousel/Carousel";

import { storeState } from "../../../../store/reducers/mainReducer/MainReducer";
import { connect } from "react-redux";
import { HeaderData } from "../../../../store/types";
import Header from "../../molecules/header/Header";
import diamond from '../../../../assets/icons/diamond.png'

interface Props {
  headerSlides: HeaderData[] | undefined;
}

const HeaderSection = ({ headerSlides }: Props) => {
  return (
    <React.Fragment>
      <Header />
      <Diamond.Header>
      <Diamond.Logo>
        <Diamond.ImageLogo src={diamond} alt="das"/>
        Diamond
        <br />
        <Diamond.Span>beauty studio</Diamond.Span>
      </Diamond.Logo>

      <Carousel
        steps={headerSlides}
        swipe={false}
        interval={10000}
        styled={{ height: 700, maxHeight: 600, maxHeightPhone: 400, filter: true }}
      />
    </Diamond.Header>
    </React.Fragment>
  );
};

const mapStateToProps = (state: { main: storeState }) => ({
  headerSlides: state.main.headers
});

export default connect(mapStateToProps)(HeaderSection);
