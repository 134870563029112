import styled from "styled-components";

interface Props {
  iconLogo?: boolean;
}

export default {
  Header: styled.header`
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    @media (max-width: 900px) {
      height: 600px;
    }
    @media (max-width: 600px) {
      height: 400px;
    }
  `,
  Logo: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    height: auto;
    color: white;
    font-size: 60px;
    text-transform: uppercase;
    font-family: HK-Groteska;
    letter-spacing: 4px;
    text-align: center;
    @media (max-width: 600px) {
      font-size: 45px;
    }
  `,
  ImageLogo: styled.img<Props>`
    width: ${(props: Props) => (props.iconLogo ? "85px" : "115px")};
    filter: ${(props: Props) => (props.iconLogo ? "" : " invert(1)")};
  `,
  Span: styled.span`
    font-size: 23px;
    line-height: 10px;
    text-transform: initial;
    @media (max-width: 600px) {
      font-size: 19px;
    }
  `,
  MenuBlock: styled.div`
    width: 100%;
    height: 80px;
	  @media (max-width: 815px) {
		  height: 50px;
	  },
  `,
  Menu: styled.div`
    box-shadow: 0px 0px 22px 0px black;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white;
    z-index: 2;
    width: 100%;
    height: 80px;
    display: flex;
    position: fixed;
	 @media (max-width: 815px) {
		height: 50px;
	 },
  `,
  ServicePopup: styled.div`
    position: absolute;
    width: 200px;
    height: auto;
    left: 0;
    top: 80px;
    z-index: 2;
    @media (max-width: 815px) {
		top: 50px;
	 },
  `,
  Sociality: styled.div`
    width: 120px;
    height: 60px;
    position: absolute;
    right: 0px;
    top: 27px;
    z-index: 1;
    @media (max-width: 900px) {
      display: none;
    }
  `,
};
