import React from "react";
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import {FormStyles} from "../field/FormStyles";


interface Props {
  value: string[];
  name: string;
  label: string;
  changeInputHandler: (value: string, name: string) => void;
}

const SelectField = ({value, name, label, changeInputHandler}: Props) => {
  const classes = FormStyles();

  return (
    <React.Fragment>
      <Autocomplete
        className={classes.customFormStyle}
        multiple
        onChange={(event: object, value: any) => changeInputHandler(value, name)}
        id="tags-outlined"
        options={services}
        getOptionLabel={(option) => option}
        filterSelectedOptions
        value={value}
        renderInput={(value) => (
          <TextField
            {...value}
            variant="outlined"
            color='secondary'
            label={label}
          />
        )}
      />
    </React.Fragment>
  );
};

export default SelectField;

const services = [
  'Стрижка',
  'Укладка',
  'Зачіска',
  'Фарбування',
  'Нарощення волосся',
  'Реконструкція волосся',
  'Педікюр',
  'Манікюр',
  'Нарощення нігтів',
  'Макіяж',
  'Перманентний макіяж',
  'Брови',
  'Вії',
  'Доглядова косметологія',
  'Апаратна косметологія',
  `Ін'єкційна косметологія`,
  'Масаж',
  'Обгортання',
  'Шугарінг',
  'Воскова депіляція',
];
