import React from "react";
import { IconButton } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { toggleFormStatus } from "../../../../store/actions/actions";
import { IconStyles } from "./iconStyles";

interface Props {
  icon: JSX.Element;
  path?: string;
}

const IconButt = ({ icon, path }: Props) => {
  const classes = IconStyles();
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      {path ? (
        <IconButton
          href={path}
          target="_blank"
          color="default"
          aria-label="add an alarm"
          className={classes.button}
        >
          {icon}
        </IconButton>
      ) : (
        <IconButton
          color="default"
          aria-label="add an alarm"
          className={classes.button}
          onClick={() => dispatch(toggleFormStatus(false))}
        >
          {icon}
        </IconButton>
      )}
    </React.Fragment>
  );
};

export default IconButt;
