import React from "react";
import Diamond from "./aboutStyles";
import IconButt from "../../atoms/iconButton/IconButton";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import { storeState } from "../../../../store/reducers/mainReducer/MainReducer";
import { AboutInterface } from "../../../../store/types";
import { connect } from "react-redux";

const AboutSection = ({
  phone,
  image,
  schedule,
  scheduleHoliday,
  address,
  location,
}: AboutInterface) => {
  return (
    <React.Fragment>
      <Diamond.About>
        <Diamond.AboutContainer>
          <Diamond.AboutPhoto id="about-block-id">
            <img
              src={image}
              alt="#"
              style={{ height: "100%", width: "100%" }}
            />
          </Diamond.AboutPhoto>
          <Diamond.AboutInfo>
            <h2 style={{ margin: "5px 0" }}>ФІЛОСОФІЯ</h2>
            Ми любимо те, що робимо і робимо те, що любимо.<br/> Це наша філософія.<br/>
            Ми створили DIAMOND beauty studio для всіх,<br/> хто цінує комфорт,
            якість і щирі емоції.<br/> Ми старалися продумати усі дрібниці, щоб нашим
            гостям було зручно,<br/> тепло, затишно, чисто, безпечно, гарно, смачно,
            професійно.<br/> Ми ретельно підбираємо наших майстрів,<br/> щоб послуги
            приносили задоволення і крутий результат.<br/> Ми прагнемо розширювати
            асортимент наших послуг,<br/> щоб всі послуги краси можна було отримати в
            нашому салоні і<br/> витратити на це мінімум часу.<br/> Наша місія - створити
            суспільство зі здоровою самооцінкою,<br/> впевнене у своїй унікальній
            привабливості.<br/> Зробити красу ближчою і доступнішою для кожного,<br/> хто
            прагне самовдосконалення.<br/>⠀Відкрий для себе наш дивовижний світ<br/>
            DIAMOND - місце твого відпочинку та відновлення.<br/>⠀ЗАСЯЙ РАЗОМ З
            НАМИ.
          </Diamond.AboutInfo>
        </Diamond.AboutContainer>
      </Diamond.About>
      <Diamond.ContactsContainer id="contacts-block-id">
        <Diamond.ContactsInfo>
          <Diamond.ContactsData>
            <Diamond.ContactsDiv>
              <Diamond.ContactsHeader>ЧЕКАЄМО НА ВАС</Diamond.ContactsHeader>
              <Diamond.ContactsParagraph>
                {schedule
                  ? schedule
                  : "Щось пішло не так! Вибачте за незручності"}
              </Diamond.ContactsParagraph>
              <Diamond.ContactsParagraph>
                {scheduleHoliday
                  ? scheduleHoliday
                  : "Щось пішло не так! Вибачте за незручності"}
              </Diamond.ContactsParagraph>
            </Diamond.ContactsDiv>
          </Diamond.ContactsData>
          <Diamond.ContactsData>
            <Diamond.ContactsDiv>
              <Diamond.ContactsHeader>МИ ЗНАХОДИМОСЬ</Diamond.ContactsHeader>
              <Diamond.ContactsParagraph>
                {address
                  ? address
                  : "Щось пішло не так! Вибачте за незручності"}
                <br />
                {location
                  ? location
                  : "Щось пішло не так! Вибачте за незручності"}
              </Diamond.ContactsParagraph>
            </Diamond.ContactsDiv>
          </Diamond.ContactsData>
          <Diamond.ContactsData>
            <Diamond.ContactsDiv>
              <Diamond.ContactsHeader>ДЗВОНІТЬ НАМ</Diamond.ContactsHeader>
              <Diamond.ContactsParagraph>
                {phone ? phone : "Щось пішло не так! Вибачте за незручності"}
              </Diamond.ContactsParagraph>
            </Diamond.ContactsDiv>
          </Diamond.ContactsData>
        </Diamond.ContactsInfo>
        <Diamond.ContactsSociality>
          <IconButt
            icon={<FacebookIcon />}
            path="https://www.facebook.com/diamondbslviv/"
          />
          <IconButt
            icon={<InstagramIcon />}
            path="https://instagram.com/diamond.beauty.studio.lviv?igshid=y7athomk9zf5"
          />
        </Diamond.ContactsSociality>
      </Diamond.ContactsContainer>
    </React.Fragment>
  );
};

const mapStateToProps = (state: { main: storeState }) => ({
  phone: state.main.about.phone,
  image: state.main.about.image,
  schedule: state.main.about.schedule,
  scheduleHoliday: state.main.about.scheduleHoliday,
  address: state.main.about.address,
  location: state.main.about.location,
});

export default connect(mapStateToProps)(AboutSection);
