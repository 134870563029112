import React from "react";
import Diamond from "./headerStyles";
import IconButt from "../../atoms/iconButton/IconButton";
import MainMenu from "../mainMenu/MainMenu";

import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";

const Header = () => {

  return (
    <Diamond.MenuBlock>
        <Diamond.Menu>
          <MainMenu />
          <Diamond.Sociality>
            <IconButt icon={<FacebookIcon />} path='https://www.facebook.com/diamondbslviv/' />
            <IconButt icon={<InstagramIcon />} path='https://instagram.com/diamond.beauty.studio.lviv?igshid=y7athomk9zf5'/>
          </Diamond.Sociality>
        </Diamond.Menu>
    </Diamond.MenuBlock>
  );
};

export default Header;
