import {BUTTONS_ARE_OPEN} from "./actions/actionTypes";
import {OpenCurrentButtons} from "./types";
import {setButtonsPrice} from "./actions/actions";

export function openCurrentButtons({dispatch}: any) {
  return function (next: any) {
    return function (action: OpenCurrentButtons) {
      const {type, index} = action;
      if (type === BUTTONS_ARE_OPEN) {
        switch (index) {
          case 1:
            dispatch(setButtonsPrice(
              ['Стрижки', 'Укладки та зачіски', 'Фарбування', 'Реконструкція волосся', 'Нарощення волосся']
            ));
            break;
          case 2:
            dispatch(setButtonsPrice(
              ['Ручки', 'Ніжки', 'Нарощення нігтів']
            ));
            break;
          case 3:
            dispatch(setButtonsPrice(
              ['Макіяж', 'Брови та вії', 'Перманентний макіяж']
            ));
            break;
          case 4:
            dispatch(setButtonsPrice(
              ['Доглядова косметологія', 'Апаратна косметологія', 'Пілінги', "Ін'єкційна косметологія", "Лікування акне elos-лазер", "Лазерна епіляція"]
            ));
            break;
          case 5:
            dispatch(setButtonsPrice(
              ['Масаж', 'Обгортання', 'Шугарінг', 'Воскова депіляція']
            ));
            break;
        }
      }
      return next(action)
    }
  }
}
