import { makeStyles, Theme } from "@material-ui/core/styles";

export interface StyleProps {
  height: number;
  maxHeight?: number;
  maxHeightPhone?: number;
  filter?: boolean;
}

export const carouselStyles = makeStyles<Theme, StyleProps>({
    root: props => ({
      width: '100%',
      flexGrow: 1,
      position: "absolute",
      zIndex: 0,
      filter: props.filter ? 'contrast(0.4)' : 'none',
    }),
    img: props => ({
      height: props.height,
      maxHeight: props.height,
      display: "block",
      width: "100%",
      overflow: "hidden",
       "@media (max-width: 900px)": {
			 maxHeight: props.maxHeight,
		 },
		 "@media (max-width: 600px)": {
			 maxHeight: props.maxHeightPhone,
		 },
    }),
    discount: {
      fontSize: 22,
      height: 100,
      fontFamily: 'Roboto-Condensed',
      textAlign: 'center',
      letterSpacing: '1px',
      width: '95% !important',
      margin: '0 auto',
      color: 'black',
      display: 'flex !important',
      justifyContent: 'center',
      alignItems: 'center',
      "@media (max-width: 500px)": {
        fontSize: 19,
      },
    },
    customDot: {
      width: 10,
      height: 10,
      color: '#b8860b',
      fontSize: 15,
      "&:hover": {
        color: 'black',
        fontSize: 17,
      },
      "&::before": {
        content: '"⬤"',
      }
    }
  });
