import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";

import Diamond from "./sendStyles";
import Field from "../../atoms/field/Field";
import PhoneField from "../../atoms/phoneField/PhoneField";
import SelectField from "../../atoms/selectFiled/SelectField";
import TimeRangePicker from "../../atoms/timePicker/TimePicker";
import DateRangePicker from "../../atoms/datePicker/DatePicker";
import { VERIFICATION_MESSAGE } from "../../../../store/actions/actionTypes";
import { Grid, Button } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {toggleFormStatus, updateFormValues} from "../../../../store/actions/actions";
import { FormStyles } from "../../atoms/field/FormStyles";

interface Props {
  isForm: boolean;
  defaultDescription: string;
}

interface State {
  name: string;
  phone: string;
  service: string[];
  time: Date | null;
  date: Date | null;
  description?: string;
}

const initialState = {
  name: "",
  phone: "",
  service: [],
  time: null,
  date: null,
  description: "",
};

const SendForm = ({ isForm, defaultDescription }: Props) => {

  const dispatch = useDispatch();
  const classes = FormStyles();
  const [values, setValues] = useState<State>(initialState);


  useEffect(() => {
    setValues({
      ...values,
      description: defaultDescription,
    })
  }, [defaultDescription])

  const changeInputHandler = (
    value: string | Date | string[],
    name: string
  ) => {
    setValues({
      ...values,
      [name]: value,
    });
    dispatch(updateFormValues(values))
  };

  const handleSubmit = () => {
    const sendMessage = {
      name: values.name,
      phone: values.phone,
      service: values.service,
      date: values.date,
      time: values.time,
      description: values.description,
    };
    dispatch({ type: VERIFICATION_MESSAGE, sendMessage });
  };

  useEffect(() => {
    if (!isForm) setValues(initialState);
  }, [isForm]);

  return (
    <Dialog
      className={classes.root}
      open={isForm!}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        dispatch(toggleFormStatus(false));
        setValues(initialState);
      }}
    >
      <Diamond.Div>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(toggleFormStatus(false));
              setValues(initialState);
            }}
            color="default"
          >
            <CloseIcon />
          </Button>
        </DialogActions>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xl={6} md={12} sm={12} xs={12}>
              <Field
                changeInputHandler={changeInputHandler}
                name="name"
                label={`Ваше ім'я *`}
                value={values.name!}
              />
            </Grid>
            <Grid item xl={6} md={6} sm={12} xs={12}>
              <PhoneField
                changeInputHandler={changeInputHandler}
                value={values.phone!}
                name="phone"
                placeholder="Ваш телефон *"
              />
            </Grid>
            <Grid item xl={6} md={6} sm={12} xs={12}>
              <SelectField
                changeInputHandler={changeInputHandler}
                value={values.service!}
                name="service"
                label={`Оберіть послугу *`}
              />
            </Grid>
            <Grid item xl={6} md={6} sm={12} xs={12}>
              <DateRangePicker
                changeInputHandler={changeInputHandler}
                date={values.date}
                name="date"
                label={`Оберіть зручну дату`}
              />
            </Grid>
            <Grid item xl={6} md={6} sm={12} xs={12}>
              <TimeRangePicker
                changeInputHandler={changeInputHandler}
                time={values.time}
                name="time"
                label={`Оберіть зручний час`}
              />
            </Grid>
            <Grid item xl={6} md={12} sm={12} xs={12}>
              <Field
                changeInputHandler={changeInputHandler}
                name="description"
                label={`Ваші побажання`}
                rows={`4`}
                value={values.description!}
                multiline
              />
            </Grid>
          </Grid>
        </DialogContent>

        <Diamond.FormFooter>
          <Button
            className={classes.button}
            onClick={handleSubmit}
            variant="outlined"
            color="default"
            style={{
              width: "50%",
              padding: "15px 15px",
              fontFamily: "Roboto-Condensed",
              fontSize: "22px",
            }}
          >
            Записатись
          </Button>
        </Diamond.FormFooter>
      </Diamond.Div>
    </Dialog>
  );
};

const mapStateToProps = (state: any) => ({
  isForm: state.app.isForm,
  defaultDescription: state.main.formValues.description,
});

export default connect(mapStateToProps)(SendForm);

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);
