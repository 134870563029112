import React, { Fragment } from "react";
import { DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from '@material-ui/core/styles';
import ukLocale from "date-fns/locale/uk";
import { FormStyles } from "../field/FormStyles";

interface Props {
  name: string;
  label: string;
  changeInputHandler: (value: string, name: string) => void;
  date: Date | null;
}
let currentYear = new Date().getFullYear();
let currentMonth = new Date().getMonth() + 1;
let currentDay = new Date().getDate();
let maximumDate = new Date(currentYear, currentMonth, currentDay);

const DateRangePicker = ({ name, label, changeInputHandler, date }: Props) => {
  const classes = FormStyles();
  return (
    <Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ukLocale}>
        <ThemeProvider theme={defaultMaterialTheme}>
          <DatePicker
            className={classes.customFormStyle}
            maxDate={maximumDate}
            minDate={new Date()}
            margin="normal"
            id="date-picker-dialog"
            label={label}
            format="MM/dd/yyyy"
            value={date}
            name={name}
            inputVariant="outlined"
            onChange={(event: any) => changeInputHandler(event, name)}
            style={{ margin: 0 }}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </Fragment>
  );
};

const defaultMaterialTheme = createTheme({
  palette: {
    primary: {
      main: "#B8860B",
    },
  },
});

export default DateRangePicker;
