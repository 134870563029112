import { call, put } from "redux-saga/effects";
import {
    FETCH_PRICE_SUCCESSES,
    FETCH_PRICE_ERROR
} from "../../actions/actionTypes";
import { RequestPrice } from "../../types";
import { pricesApi } from "../../api";
import {toggleSpinerStatus, toggleToastStatus} from "../../actions/actions";

export function* getPrice({price}: RequestPrice) {
    try {
        yield put(toggleSpinerStatus(true));
        const response = yield call(() => pricesApi(price));
        yield put({ type: FETCH_PRICE_SUCCESSES, payload: response });
        yield put(toggleSpinerStatus(false));
    } catch (error) {
        yield put({ type: FETCH_PRICE_ERROR, payload: error.message });
        yield put(toggleSpinerStatus(false));
        yield put(toggleToastStatus({variant: 'error', toastText: `Щось пішло не так...`}));
    }
}
