import { call, put } from "redux-saga/effects";
import { messageApi } from "../../api";
import {
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_ERROR
} from "../../actions/actionTypes";
import { RequestData } from "../../types";
import {toggleFormStatus, toggleSpinerStatus, toggleToastStatus} from "../../actions/actions";



export function* sendMessage(request: RequestData) {
    const {sendMessage} = request;
  try {
    yield put(toggleSpinerStatus(true));
    const response: Response = yield call(() => messageApi(sendMessage));
    if(response.status === 200) {
		 yield put({ type: SEND_MESSAGE_SUCCESS, payload: response });
		 yield put(toggleSpinerStatus(false));
		 yield put(toggleToastStatus({
			 variant: 'success',
			 toastText: `Дякуємо за заявку! Ми зв'яжемось з вами найближчим часом`
		 }));
		 yield put(toggleFormStatus(false));
	 } else {
		 yield put(toggleSpinerStatus(false));
		 yield put(toggleToastStatus({variant: 'error', toastText: `Щось пішло не так... Ви можете нам зателефонувати!`}));
	 }
  } catch (error) {
    yield put({ type: SEND_MESSAGE_ERROR, payload: error.message });
    yield put(toggleSpinerStatus(false));
    yield put(toggleToastStatus({variant: 'error', toastText: `Щось пішло не так... Ви можете нам зателефонувати!`}));
  }
}
