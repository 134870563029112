import React from "react";
import ReactMapboxGl, { Marker } from 'react-mapbox-gl';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import '../../../custom.css';


const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoiZGlhbW9uZC1iZWF1dHktc3R1ZGlvIiwiYSI6ImNrOXFxeTZnMDA1dGkzZXFreGZ5M2J1YzgifQ.SIG6UlP0mUPhmVHd0Ya6Eg',
  maxZoom: 19,
  minZoom: 7,
});


const LocationSection = () => {

  return (
    <Map
      style="mapbox://styles/mapbox/streets-v9"
      center={[24.024098, 49.785980]}
      className={'adaptive-for-map'}
      containerStyle={{
        height: '600px',
        width: '100%'
      }}
      zoom={[15]}
    >
      <Marker coordinates={[24.024098, 49.785980]}>
        <RoomOutlinedIcon fontSize='large'/>
      </Marker>
    </Map>
  )
}

export default LocationSection;