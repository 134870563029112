import React from "react";
import LazyLoad from 'react-lazyload';
import {connect, useDispatch} from "react-redux";
import Diamond from "./mainStyles";
import wave from '../../../../assets/icons/wave.png';
import CustomButton from "../../atoms/customButton/CustomButton";
import {togglePriceStatus} from "../../../../store/actions/actions";
import {BUTTONS_ARE_OPEN} from "../../../../store/actions/actionTypes";
import {MainInterface} from "../../../../store/types";
interface Props {
  mainInfo: MainInterface,
}

const MainSection = (
  {
    mainInfo: {
      hair,
      nails,
      face,
      cosmetology,
      body
    }
  }: Props) => {
  const dispatch = useDispatch();
  const handlePrice = (index?: number) => {
    dispatch({type: BUTTONS_ARE_OPEN, index});
    dispatch(togglePriceStatus(true));
  };

  return (
    <Diamond.ServiceCont>
      <Diamond.ServiceContainer className={'custom-div'} id='hair-block-id'>
        <LazyLoad height={'auto'}>
          <Diamond.Service>
            <h3>Hair Experts</h3>
            <p>{hair.text}</p>
            <img src={wave} alt="#" width={145}/>
            <CustomButton style={PriceStyles} textBtn='Прайс' index={1} clickFunc={handlePrice}/>
          </Diamond.Service>
          <Diamond.Service>
            <img
              src={hair && hair.image}
              alt='Вибачте! Щось пішло не так...'
              width={'100%'} height={'100%'}
            />
          </Diamond.Service>
        </LazyLoad>
      </Diamond.ServiceContainer>
      <Diamond.ServiceContainer className={'custom-div'} id='nail-block-id'>
        <LazyLoad height={'auto'}>
          <Diamond.Service>
            <img
              src={nails && nails.image}
              alt='Вибачте! Щось пішло не так...'
              width={'100%'} height={'100%'}
            />
          </Diamond.Service>
          <Diamond.Service>
            <h3>Nail Experts</h3>
            <p>{nails.text}</p>
            <img src={wave} alt="#" width={145}/>
            <CustomButton style={PriceStyles} textBtn='Прайс' index={2} clickFunc={handlePrice}/>
          </Diamond.Service>
        </LazyLoad>
      </Diamond.ServiceContainer>
      <Diamond.ServiceContainer className={'custom-div'} id='face-block-id'>
        <LazyLoad height={'auto'}>
          <Diamond.Service>
            <h3>Face Artist</h3>
            <p>{face.text}</p>
            <img src={wave} alt="#" width={145}/>
            <CustomButton style={PriceStyles} textBtn='Прайс' index={3} clickFunc={handlePrice}/>
          </Diamond.Service>
          <Diamond.Service>
            <img
              src={face && face.image}
              alt='Вибачте! Щось пішло не так...'
              width={'100%'} height={'100%'}
            />
          </Diamond.Service>
        </LazyLoad>
      </Diamond.ServiceContainer>
      <Diamond.ServiceContainer id='cosmetology-block-id'>
        <LazyLoad height={'auto'}>
          <Diamond.Service>
            <img
              src={cosmetology && cosmetology.image}
              alt='Вибачте! Щось пішло не так...'
              width={'100%'} height={'100%'}
            />
          </Diamond.Service>
          <Diamond.Service>
            <h3>Cosmetology</h3>
            <p>{cosmetology.text}</p>
            <img src={wave} alt="#" width={145}/>
            <CustomButton style={PriceStyles} textBtn='Прайс' index={4} clickFunc={handlePrice}/>
          </Diamond.Service>
        </LazyLoad>
      </Diamond.ServiceContainer>
      <Diamond.ServiceContainer id='body-block-id'>
        <LazyLoad height={'auto'}>
          <Diamond.Service>
            <h3>Body Experts</h3>
            <p>{body.text}</p>
            <img src={wave} alt="#" width={145}/>
            <CustomButton style={PriceStyles} textBtn='Прайс' index={5} clickFunc={handlePrice}/>
          </Diamond.Service>
          <Diamond.Service>
            <img
              src={body && body.image}
              alt='Вибачте! Щось пішло не так...'
              width={'100%'} height={'100%'}
            />
          </Diamond.Service>
        </LazyLoad>
      </Diamond.ServiceContainer>
    </Diamond.ServiceCont>
);
};

const mapStateToProps = (state: any) => ({mainInfo: state.main.mainData})

export default connect(mapStateToProps)(MainSection);

const PriceStyles = {
  padding: '10px 25px',
  marginTop: '0',
  color: '#B8860B',
  fontFamily: 'Roboto-Condensed',
  fontSize: '25px',
};
