import { call, put } from "redux-saga/effects";
import {
  FETCH_ABOUT_SUCCESSES,
  FETCH_ABOUT_ERROR,
} from "../../actions/actionTypes";
import { aboutApi } from "../../api";
import { toggleSpinerStatus, toggleToastStatus } from "../../actions/actions";

export function* getAbout() {
  try {
    yield put(toggleSpinerStatus(true));
    const response = yield call(() => aboutApi());
    yield put({ type: FETCH_ABOUT_SUCCESSES, payload: response });
    yield put(toggleSpinerStatus(false));
  } catch (error) {
    yield put({ type: FETCH_ABOUT_ERROR, payload: error.message });
    yield put(toggleSpinerStatus(false));
    yield put(
      toggleToastStatus({ variant: "error", toastText: `Щось пішло не так...` })
    );
  }
}
