import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, connect } from "react-redux";
import "../../../custom.css";
import { clearPrices, togglePriceStatus } from "../../../../store/actions/actions";
import ButtonPrice from "../../atoms/buttonPrice/ButtonPrice";
import { PriceStyles } from "./priceStyles";
import { PRICES_ARE_OPEN } from "../../../../store/actions/actionTypes";
import Price from "../../atoms/price/Price";

interface Props {
  isPrice: boolean;
  buttonArray: string[];
  prices: any;
}

function ModalPriceButton({ isPrice, buttonArray, prices }: Props) {
  const dispatch = useDispatch();
  const classes = PriceStyles();

  const handlePriceClose = () => {
    dispatch(clearPrices());
    dispatch(togglePriceStatus(false));
  };

  const openCurrentPrice = (priceName?: string) => {
    dispatch({ type: PRICES_ARE_OPEN, price: priceName });
  };

  return (
    <Dialog
      id="custom-zIndex"
      open={isPrice!}
      TransitionComponent={Transition}
      keepMounted
      onClose={handlePriceClose}
      className={classes.root}
    >
      <DialogActions>
        <Button onClick={handlePriceClose} color="default">
          <CloseIcon />
        </Button>
      </DialogActions>
      <DialogContent className={classes.root}>
        {buttonArray && buttonArray.length > 0
          ? buttonArray.map((item: string, index: number) => (
              <ButtonPrice
                key={index}
                title={item}
                clickFunk={openCurrentPrice}
              />
            ))
          : null}
      </DialogContent>
      {!!prices.length ? <Price prices={prices} /> : null}
    </Dialog>
  );
}

const mapStateToProps = (state: any) => ({
  buttonArray: state.main.priceButtonsName,
  isPrice: state.app.isPrice,
  prices: state.main.prices,
});

export default connect(mapStateToProps)(ModalPriceButton);

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);
