import React from "react";
import TextField from "@material-ui/core/TextField";
import {FormStyles} from "./FormStyles";

interface Props {
  value: string;
  name: string;
  label: string;
  rows?: string;
  multiline?: boolean;
  changeInputHandler: (value: string, name: string) => void;
}

const Field = ({value, name, label, rows, multiline, changeInputHandler}: Props) => {
	const classes = FormStyles();
  return (
      <React.Fragment>
        <TextField
			   className={classes.customFormStyle}
            id="outlined-secondary"
            label={label}
				    value={value}
            variant="outlined"
            name={name}
            type='text'
            multiline={multiline ? multiline : false}
            rows={rows}
            onChange={(event) => changeInputHandler(event.target.value, name)}
        />
      </React.Fragment>
  );
};

export default Field;
