import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Diamond from "./mainStyles";
import Button from "@material-ui/core/Button";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import DiscountSection from "../../components/mainPage/organisms/discountSection/DiscountSection";
import AboutSection from "../../components/mainPage/organisms/aboutSection/AboutSection";
import LocationSection from "../../components/mainPage/organisms/locationSection/LocationSection";
import MainSection from "../../components/mainPage/organisms/mainSection/MainSection";
import HeaderSection from "../../components/mainPage/organisms/headerSection/HeaderSection";

import {
  FETCH_DISCOUNT,
  FETCH_HEADER,
  FETCH_ABOUT,
  FETCH_MAIN,
} from "../../store/actions/actionTypes";
import {ToastInterface} from "../../store/types";
import SendForm from "../../components/mainPage/molecules/sendForm/SendForm";
import Toast from "../../components/mainPage/atoms/toast/Toast";
import ModalPriceButton from "../../components/mainPage/molecules/madalPriceButton/ModalPriceButton";
import Spinner from "../../components/mainPage/atoms/spinner/Spinner";

interface Props {
  fetchDiscounts: () => void;
  fetchHeaders: () => void;
  fetchAbout: () => void;
  fetchMain: () => void;
  isSpinner: boolean;
  isForm: boolean;
  toast: ToastInterface;
}

const Main = ({
  fetchDiscounts,
  fetchHeaders,
  fetchAbout,
  fetchMain,
  isSpinner,
  toast,
              }: Props) => {
  const [isOpenScrollButton, setIsOpenScrollButton] = useState<boolean>(false);

  useEffect(() => {
    fetchDiscounts();
    fetchHeaders();
    fetchAbout();
    fetchMain();
  }, [fetchDiscounts, fetchHeaders, fetchAbout, fetchMain]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.pageYOffset > 600
        ? setIsOpenScrollButton(true)
        : setIsOpenScrollButton(false);
    });
  });

  return (
    <React.Fragment>
      <Diamond.Main>
        <Toast toast={toast}/>
        <HeaderSection/>
        <DiscountSection/>
        <MainSection/>
        <AboutSection/>
        <LocationSection/>
      </Diamond.Main>
      <SendForm/>
      <ModalPriceButton/>
      {isSpinner ? <Spinner/> : null}
      <Diamond.ToTopButton isOpenScrollButton={isOpenScrollButton}>
        <Button
          onClick={() => window.scrollTo(0, 0)}
          variant="contained"
          style={{borderRadius: 8, background: "#B8860B"}}
        >
          <KeyboardArrowUpIcon/>
        </Button>
      </Diamond.ToTopButton>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  isSpinner: state.app.isSpinner,
  isForm: state.app.isForm,
  toast: state.app.toast,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchDiscounts: () => dispatch({type: FETCH_DISCOUNT}),
  fetchHeaders: () => dispatch({type: FETCH_HEADER}),
  fetchAbout: () => dispatch({type: FETCH_ABOUT}),
  fetchMain: () => dispatch({type: FETCH_MAIN}),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
