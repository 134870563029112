import styled from "styled-components";

export default {
  Page: styled.div`
    min-height: 100vh;
    height: auto;
    background: #fff;
    position: relative;
    display: flex;
    justify-content: center;
  `,
};
