import styled from "styled-components";

export default {
    DivBlock: styled.div`
    width: 100%;
    height: 100vh;
    background: #00000054;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  FormHeader: styled.header`
    display: flex;
    justify-content: flex-end;
    padding: 5px 0;
  `,
  FormFooter: styled.footer`
    display: flex;
    justify-content: center;
    padding: 10px 0 0 0;
    min-height: 60px;
`,
  Div: styled.div`
    display: flex;
    width: 100%;
    border-radius: 6px;
    background: white;
    justify-content: flex-start;
    flex-direction: column;
    height: auto;
    padding-bottom: 20px;
    overflow: auto;
    @media (max-width: 600px) {
      width: 100%;
      height: 100%;
    }
  `,
}