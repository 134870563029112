import styled from "styled-components";

interface Props {
  isOpenScrollButton?: boolean;
}

export default {
  Main: styled.div`
    width: 100%;
    height: auto;
    overflow-x: hidden;
  `,
  ToTopButton: styled.div`
    display: ${(props: Props) => (props.isOpenScrollButton ? "flex" : "none")};
    position: fixed;
    bottom: 50px;
    right: 50px;
    @media (max-width: 900px) {
      left: 30px;
      bottom: 30px;
      right: inherit;
    }
  `,
};
